import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { ScrollOffset } from '../scrollTo';

import style from './style.module.scss';

export const Header = ({ fixed, mastheadRef, siteTitle }) => {
  const { masthead, mock, mastheadSI, mockSI } = useStaticQuery(graphql`
    query {
      masthead: file(relativePath: { eq: "masthead.svg" }) {
        publicURL
      },
      mock: file(relativePath: { eq: "masthead.png" }) {
        childImageSharp {
          resize {
            aspectRatio
          }
        }
      },
      mastheadSI: file(relativePath: { eq: "masthead-si.svg" }) {
        publicURL
      },
      mockSI: file(relativePath: { eq: "masthead-si.png" }) {
        childImageSharp {
          resize {
            aspectRatio
          }
        }
      },
    }
  `);

  return (
    <header className={style.header}>
      <div className={style.siWrap}>
        <div 
          className={style.si}
          style={{ 
            backgroundImage: `url(${mastheadSI.publicURL})`,
            paddingBottom: `${100/mockSI.childImageSharp.resize.aspectRatio}%` 
          }}
          ></div>
      </div>
      <div
        className={`${style.masthead} ${fixed ? style.fixed : ''}`}
        >
        <ScrollOffset>
          <div className={style.mastheadImg} ref={mastheadRef} style={{ 
            backgroundImage: `url(${masthead.publicURL})` ,
            paddingBottom: `${100/mock.childImageSharp.resize.aspectRatio}%`
            }}>
            <h1>
              {siteTitle}
            </h1>
          </div>
        </ScrollOffset>
      </div>
    </header>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
