/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ScrollTrigger } from '../components/scrollTo';

import Header from "./header"
import "./layout.css"

const getVerticalMargin = (el) => {
  const style = window.getComputedStyle(el);
  const top = parseInt(style.getPropertyValue('margin-top'));
  const bottom = parseInt(style.getPropertyValue('margin-bottom'));
  return top + bottom;
}

const Layout = ({ children, scrollStopEl }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  
  const mastheadEl = useRef(null);
  const [isFixed, setIsFixed] = useState(true);

  const doScrollStop = () => {
    if (!mastheadEl.current || !scrollStopEl) return;
    const mastheadBB = mastheadEl.current.getBoundingClientRect();
    const mastheadBottom = mastheadBB.height + getVerticalMargin(mastheadEl.current);
    const scrollStopBB = scrollStopEl.current.getBoundingClientRect();
    const scrollStopTop = scrollStopBB.top;
    setIsFixed(!(mastheadBottom - scrollStopTop > 0));
  }

  useEffect(() => {
    window.addEventListener('scroll', doScrollStop);
    window.addEventListener('resize', doScrollStop);
    return () => {
      window.removeEventListener('scroll', doScrollStop);
      window.removeEventListener('resize', doScrollStop);
    }
  }, []);

  return (
    <>
      <ScrollTrigger>
        <Header
          mastheadRef={(el) => mastheadEl.current = el}
          siteTitle={data.site.siteMetadata.title}
          fixed={isFixed} 
          />
      </ScrollTrigger>
      <main>{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
