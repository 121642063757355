import React, { createContext } from 'react'
import { polyfill } from 'smoothscroll-polyfill';
if (typeof window !== 'undefined') {
  polyfill();
}


// scroll implementation creates a sudden jump to the 
// top of the page if the action is triggered again
// while it's still working. So we wait a while before
// letting the user try again.
const TIMEOUT = 5000;

const ScrollContext = createContext();

const scrollTo = (ctx) => {
  const targetEl = ctx.target;
  if (!targetEl || Date.now() - ctx.lastScroll < TIMEOUT) { return; }
  ctx.lastScroll = Date.now();
  const html = document.documentElement;
  const bb = targetEl.getBoundingClientRect();
  const offset = ctx.offset ? ctx.offset.getBoundingClientRect().height : 0;
  const amt = html.scrollTop + bb.top - offset;
  if ('scroll' in window) {
    window.scroll({
      top: amt,
      behavior: 'smooth'
    });
  } else {
    html.scrollTop = amt;
  }

};

export const Scrollable = ({ children }) => (
  <ScrollContext.Provider value={{ target: null, offset: null }}>
    { children }
  </ScrollContext.Provider>
);

export const ScrollOffset = ({ children }) => (
  <ScrollContext.Consumer>
    {(ctx) => (
      <div ref={(el) => ctx.offset = el} style={{ overflow: 'hidden' }}>
        { children }
      </div>
    )}
  </ScrollContext.Consumer>
)

export const ScrollTrigger = ({ children }) => (
  <ScrollContext.Consumer>
    {(ctx) => (
      <div onClick={() => scrollTo(ctx)} style={{ cursor: "pointer" }}>
        { children }
      </div>
    )}
  </ScrollContext.Consumer>
);

export const ScrollTarget = ({ children }) => (
  <ScrollContext.Consumer>
    {(ctx) => (
      <div ref={(el) => ctx.target = el}>
        {children}
      </div>
    )}
  </ScrollContext.Consumer>
)